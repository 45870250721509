<!--
 * @Author: your name
 * @Date: 2021-12-15 11:02:33
 * @LastEditTime: 2021-12-24 15:54:24
 * @LastEditors: Please set LastEditors
 * @Description: 多功能无人机
 * @FilePath: \new-website-1123\src\views\special\MultifunctionalUAV\index.vue
-->
<template>
  <div class="uva-page">
    <div class="sticky-box1">
      <div class="sticky-child">
        <page1></page1>
      </div>
    </div>
    <page2 class="opacity-page scale-page"></page2>
    <page3 class="opacity-page scale-page"></page3>
    <page4 class="opacity-page">
      <img
        slot="img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/uva2/5.jpg"
        alt="上太科技"
      />
      <div class="content-div" style="padding-left: 20px">
        <span class="title-span">农田AI测绘图 </span>
        <span class="title-span">全地形自主作业</span>
        <p>
          全自主飞行拍摄、拼接和分析农田影像，生成农田高清地图和 AI
          测绘图，精确识别农田边界与作物生长趋势，适应于全地形自主作业
        </p>
      </div>
    </page4>

    <page4 :img-order="3" class="opacity-page">
      <img
        slot="img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/uva2/6.jpg"
        alt="上太科技"
      />
      <div class="content-div">
        <span class="title-span">高效精准作业</span>
        <p>
          将强大的人工智能算法，高性能动力总成，与灵活的任务系统完美结合，实现全自主农田测绘、精准喷洒和播撒作业
        </p>
      </div>
    </page4>

    <div class="sticky-box2 opacity-page">
      <div class="sticky-child">
        <page5></page5>
      </div>
    </div>
    <page6 class="opacity-page"></page6>
  </div>
</template>

<script>
import page1 from "./page1.vue";
import page2 from "./page2.vue";
import page3 from "./page3.vue";
import page4 from "../uva/page4.vue";
import page5 from "./page5.vue";
import page6 from "./page6.vue";
import {getNesViewApi} from '@/api/index.js'
export default {
  name: "uva-page2",
  components: {
    page1,
    page2,
    page3,
    page4,
    page5,
    page6,
  },
  data() {
    return {
      scrollTop: 0,
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.cScroll, false);
    this.addView()
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.cScroll, false);
  },
  methods: {
    addView(){
      getNesViewApi({type:1,pid:"ad54458f8b924454b892e6a270fa2c83"})
    },
    /**
     * @description: 监听滚动事件
     */
    cScroll() {
      let html = document.querySelector("html");
      this.scrollTop = html.scrollTop;
      if (window.innerWidth > 800) {
        this.page1s();
        this.page2s();
      }
    },
    page2s() {
      let box2 = document.querySelector(".sticky-box2");
      let div = document.querySelector(".uva-page5");
      let img = div.querySelector(".img2");
      let text = div.querySelector(".text-div");
      let ctop = this.scrollTop - box2.offsetTop;
      if (ctop > 800) {
        text.style.opacity = img.style.opacity = 1;
      } else if (ctop > 200) {
        let rate = (ctop - 200) / 600;
        text.style.opacity = img.style.opacity = rate;
      } else if (ctop < 200) {
        text.style.opacity = img.style.opacity = 0;
      }
    },
    /**
     * @description: 第一页效果
     */
    page1s() {
      let div = document.querySelector(".uva-page1");
      let title = div.querySelector(".title-div");
      let filterDiv = div.querySelector(".filter-div");
      let text = div.querySelector(".text-div");
      let label = text.querySelector(".label-span");
      let desc = text.querySelector(".desc-div");
      if (this.scrollTop > 1400) {
        let top = this.scrollTop - 1400;
        let rate = Math.min(1, top / 500);
        text.style.transform = `scale(1)`;
        text.style.opacity = 1;
        filterDiv.style.opacity = 0.5 + rate / 2;
        label.style.transform = `translateY(${(1 - rate) * 50}%)`;
        desc.style.transform = `translateY(-${(1 - rate) * 40}%)`;
        desc.style.opacity = rate;
      } else if (this.scrollTop > 900) {
        desc.style.opacity = 0;
        let top = this.scrollTop - 900;
        filterDiv.style.opacity = 0.5;
        let rate = top / 500;
        let scale = Math.max(1, (1 - rate) * 4);
        text.style.transform = `scale(${scale})`;
        text.style.opacity = rate;
      } else if (this.scrollTop > 600) {
        let top = this.scrollTop - 600;
        title.style.opacity = 0;
        let rate = top / 300 / 2;
        text.style.opacity = 0;
        filterDiv.style.opacity = rate;
      } else if (this.scrollTop > 200) {
        let top = this.scrollTop - 200;
        let rate = top / 400;
        title.style.opacity = 1 - rate;
      } else if (this.scrollTop < 200) {
        title.style.opacity = 1;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.sticky-child {
  position: sticky;
  top: 0;
}
.sticky-box1 {
  height: calc(100vh + 2400px);
}

.sticky-box2 {
  height: calc(100vh + 1100px);
}
@media screen and (max-width: $mobile-width) {
  .sticky-child {
    position: relative;
    top: 0;
  }
  .sticky-box1 {
    height: auto;
  }

  .sticky-box2 {
    height: auto;
  }
}
</style>
