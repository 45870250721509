<!--
 * @Author: your name
 * @Date: 2021-12-13 19:52:28
 * @LastEditTime: 2022-01-26 18:23:46
 * @LastEditors: Please set LastEditors
 * @Description:
 * @FilePath: \new-website-1123\src\views\special\uva\page1.vue
-->
<template>
  <div class="uva-page1 pt80">
    <div class="title-div">
      <d-title model="多功能无人机" style="margin-top: 40px"></d-title>
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/uva2/1.164.png"
        alt="上太科技"
      />
    </div>

    <div class="img-div img2-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/uva2/1.164.png"
        alt="上太科技"
      />
      <div class="filter-div"></div>
    </div>

    <!-- 蒙层 -->
    <div class="filter-div"></div>

    <div class="text-div">
      <span class="label-span">
        精密既高效
        <br />
        无人既放心
      </span>
      <div class="desc-div">
        <span>全新折叠桁架式结构，结实可靠，可高效收展，方便搬运;</span>
        <span>惊人载重能力，作业效率达到新高度，</span>
        <span>配合数字农业解决方案，科学指导减肥增产</span>
      </div>
    </div>
  </div>
</template>

<script>
import dTitle from "@/components/sTitle/title";
export default {
  name: "",
  components: {
    dTitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.uva-page1 {
  position: relative;
  height: 100vh;
  overflow: hidden;
  .img-div {
    position: absolute;
    top: 0;
    width: 80%;
    height: 100%;
    left: 11%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &.img2-div {
      display: none;
    }
  }
  .filter-div {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000, $alpha: 0.8);
  }
  .text-div {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .label-span {
      color: $color-active;
      font-size: 85px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      transform: translateY(50%);
    }
    .desc-div {
      opacity: 0;
      transform: translateY(-40%);
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.7);
      line-height: 40px;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .uva-page1 {
    position: relative;
    height: auto;
    overflow: hidden;
    .title-div {
      margin-top: 30px;
    }
    .img-div {
      position: relative;
      top: 0;
      width: 100%;
      left: 0;
      display: flex;
      justify-content: center;
      overflow: hidden;
      img {
        width: 150%;
        height: 100%;
        object-fit: cover;
      }
      &.img2-div {
        display: flex;
        .filter-div {
          opacity: 1;
        }
      }
    }
    .filter-div {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000, $alpha: 0.8);
    }
    .text-div {
      position: absolute;
      opacity: 1;
      top: unset;
      left: 7%;
      bottom: 10%;
      width: 86%;
      height: unset;
      text-align: center;
      .label-span {
        font-size: 0.55rem;
        transform: translateY(0);
      }
      .desc-div {
        opacity: 1;
        transform: translateY(-0);
        margin-top: 30px;
        font-size: 0.27rem;
        line-height: 0.36rem;
      }
    }
  }
}
</style>
