<!--
 * @Author: your name
 * @Date: 2021-12-13 20:12:26
 * @LastEditTime: 2021-12-24 15:30:25
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\uva\page4.vue
-->
<template>
  <div class="uva-page4 pt80">
    <div class="img-div" :class="'order-' + imgOrder">
      <slot name="img"></slot>
    </div>
    <div class="text-div">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    imgOrder: {
      type: [Number, String],
      default: 1,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.uva-page4 {
  display: flex;
  justify-content: center;
  // padding: 80px 0;
  // max-width: 1500px;
  margin: auto;
  .img-div {
    // width: 60%;
    img {
      width: 100%;
    }
    &.order-1 {
      order: 1;
    }
    &.order-3 {
      order: 3;
    }
  }
  .text-div {
    // width: 40%;
    order: 2;
    ::v-deep {
      .content-div {
        display: flex;
        flex-direction: column;
        padding-top: 90px;
        .title-span {
          font-size: 85px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: $color-active;
          line-height: 140px;
        }
        p {
          width: 526px;
          margin: 0;
          margin-top: 40px;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 300;
          color: rgba($color: #fff, $alpha: 0.6);
          line-height: 48px;
        }
      }
    }
  }
}
@media screen and (max-width: 1580px) {
  .uva-page4 {
    .text-div {
      ::v-deep {
        .content-div {
          .title-span {
            font-size: 60px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: $color-active;
            line-height: 140px;
          }
          p {
            width: 526px;
            margin: 0;
            margin-top: 40px;
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 300;
            color: rgba($color: #fff, $alpha: 0.6);
            line-height: 48px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .uva-page4 {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 0;
    .img-div {
      width: 86%;
      margin: auto;
      margin-top: 10px;
      img {
        width: 100%;
      }
      &.order-1 {
        order: 2;
      }
      &.order-3 {
        order: 2;
      }
    }
    .text-div {
      // width: 40%;
      order: 1;
      ::v-deep {
        .content-div {
          padding-top: 0.55rem;
          padding-left: 0 !important;
          margin: auto;
          transform: translateY(0) !important;
          text-align: center;
          width: 86%;
          .title-span {
            font-size: 0.55rem;
            line-height: 1.09rem;
          }
          p {
            width: 100%;
            margin: 0;
            margin-top: 0.36rem;
            font-size: 0.27rem;
            line-height: 0.44rem;
          }
        }
      }
    }
  }
}
</style>
