<!--
 * @Author: your name
 * @Date: 2021-12-15 11:25:58
 * @LastEditTime: 2021-12-24 15:49:36
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\MultifunctionalUAV\page6.vue
-->
<template>
  <div class="uva-page6 pt80">
    <div class="title-div">
      <span>高度模块化，整机防水防尘</span>
      <p class="desc-p">高度模块化设计，通用多元件，大幅度降低维修成本；</p>
      <p class="desc-p">整机防水防尘，整机清洗，人药分离，即省心，更安全</p>
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/uva2/9.png"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.uva-page6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  position: relative;
  height: 100vh;
  overflow: hidden;
  .img-div {
    margin-top: 50px;
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.title-div {
  text-align: center;
  position: relative;
  z-index: 2;
  span {
    font-size: 85px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: $color-active;
    line-height: 140px;
  }
  .desc-p {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 300;
    color: rgba($color: #fff, $alpha: 0.6);
    line-height: 48px;
    text-align: center;
  }
}
@media screen and (max-width: 1580px) {
  .title-div {
    span {
      font-size: 60px;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .uva-page6 {
    height: auto;
    .img-div {
      margin-top: 0.36rem;
      width: 180%;
      position: relative;
      img {
        object-fit: cover;
        display: block;
      }
    }
  }
  .title-div {
    text-align: center;
    position: relative;
    z-index: 2;
    width: 86%;
    margin: auto;
    span {
      font-size: 0.55rem;
      line-height: 0.75rem;
      margin-bottom: 20px;
      display: block;
    }
    .desc-p {
      font-size: 0.27rem;
      line-height: 0.44rem;
    }
  }
}
</style>
