<!--
 * @Author: your name
 * @Date: 2021-12-13 20:09:57
 * @LastEditTime: 2021-12-27 11:08:30
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\uva\page3.vue
-->
<template>
  <div class="uva-page3">
    <img
      src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/uva2/4.jpg"
      alt="上太科技"
    />
    <span class="label-span">数字化农业生产的亲密伙伴</span>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.uva-page3 {
  position: relative;
  img {
    width: 100%;
    display: block;
  }
  .label-span {
    position: absolute;
    white-space: nowrap;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 85px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: rgba($color: #fff, $alpha: 0.8);
    line-height: 140px;
  }
}

@media screen and (max-width: $mobile-width) {
  .uva-page3 {
    position: relative;
    width: 100%;
    overflow: hidden;
    img {
      width: 180%;
      display: block;
    }
    .label-span {
      position: absolute;
      white-space: normal;
      text-align: center;
      width: 86%;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.55rem;
      line-height: 1.09rem;
    }
  }
}
</style>
