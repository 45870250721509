<!--
 * @Author: your name
 * @Date: 2021-12-13 20:04:43
 * @LastEditTime: 2021-12-27 11:08:39
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\uva\page2.vue
-->
<template>
  <div class="uva-page2">
    <img
      src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/uva2/3.jpg"
      alt="上太科技"
    />
    <ul class="c-ul">
      <li>农田AI测绘图</li>
      <li>高效精准作业</li>
      <li>精密材质，结实可靠</li>
      <li>高度模块化，整机防水防尘</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.uva-page2 {
  position: relative;
  img {
    width: 100%;
    display: block;
  }
  .c-ul {
    position: absolute;
    right: 11%;
    bottom: 11%;
    font-size: 60px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: $color-active;
    line-height: 140px;
    text-align: right;
  }
}

@media screen and (max-width: $mobile-width) {
  .uva-page2 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 11rem;
    width: 100%;
    overflow: hidden;
    img {
      width: unset;
      height: 8.18rem;
      display: block;
      object-fit: cover;
    }
    .c-ul {
      position: absolute;
      right: unset;
      bottom: 11%;
      font-size: 0.55rem;
      line-height: 1.09rem;
      text-align: center;
    }
  }
}
</style>
