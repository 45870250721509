<!--
 * @Author: your name
 * @Date: 2021-12-04 15:24:47
 * @LastEditTime: 2021-12-23 14:20:01
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\components\sTitle\title.vue
-->
<template>
  <div class="d-title-box">
    <span class="din model-span">
      {{ model }}
    </span>
    <span class="c-span">
      {{ ch }}
    </span>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    model: {
      type: String,
      default: "",
    },
    ch: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.d-title-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .model-span {
    font-size: 84px;
    font-weight: 500;
    color: #ffffff;
  }
  .c-span {
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
  }
}
@media screen and (max-width: $mobile-width) {
  .d-title-box {
    .model-span {
      font-size: 0.76rem;
    }
    .c-span {
      font-size: 0.33rem;
    }
  }
}
</style>
