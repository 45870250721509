<!--
 * @Author: your name
 * @Date: 2021-12-15 11:17:42
 * @LastEditTime: 2021-12-24 15:54:54
 * @LastEditors: Please set LastEditors
 * @Description:
 * @FilePath: \new-website-1123\src\views\special\MultifunctionalUAV\page5.vue
-->
<template>
  <div class="uva-page5 pt80">
    <div class="text-div">
      <span class="label-s">精密材质，结实可靠</span>
      <span class="d-p"
        >结构强度大，重要部位增加加强筋，耐腐蚀，机身轻盈，为搭载更多任务设备提供足够载荷</span
      >
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/uva2/7.png"
        alt="上太科技"
      />
      <img
        class="img2"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/uva2/8.png"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.uva-page5 {
  position: relative;
  height: 100vh;
  .img-div {
    position: absolute;
    top: 20%;
    height: 78%;
    width: 70%;
    left: 0;
    img {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      &.img2 {
        opacity: 0;
      }
    }
  }
  .text-div {
    position: absolute;
    opacity: 0;
    top: 100px;
    left: 50%;
    .label-s {
      font-size: 85px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: $color-active;
      line-height: 140px;
    }
    .d-p {
      display: block;
      width: 621px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 300;
      color: rgba($color: #fff, $alpha: 0.6);
      line-height: 48px;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .uva-page5 {
    position: relative;
    height: auto;
    .img-div {
      position: relative;
      height: unset;
      width: 100%;
      height: 5.45rem;
      left: 0;
      img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 5.45rem;
        object-fit: contain;
        &.img2 {
          opacity: 1;
        }
      }
    }
    .text-div {
      position: relative;
      opacity: 1;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      .label-s {
        font-size: 0.55rem;
        line-height: 1rem;
      }
      .d-p {
        display: block;
        width: 86%;
        font-size: 0.27rem;
        line-height: 0.44rem;
      }
    }
  }
}
</style>
